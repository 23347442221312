import { useContext } from 'react'
import LinkBtn from '../components/LinkBtn';
import Button from './../components/Button';

import UpArrow from './../assets/icons/upArrow.svg';

import scrollIntoView from './../utils/scrollIntoView';
import AppContext from '../services/AppContext';

function Footer() {
	const {
		modal: {setShowModal},
	} = useContext(AppContext);

	return (
		<section className='relative footer-wrap'>
			<article className='lg:flex items-start mx-auto max-w-6xl'>
				{/* <div className='flex'>
					<Link
						to='/'
						className='w-52 md:w-auto'
						onClick={() => scrollIntoView('pageTop')}>
						<Logo className='mr-40' />
					</Link>
					<Button
						theme='light'
						className='ml-auto px-2 py-2 md:hidden'
						onClick={() => scrollIntoView('pageTop')}>
						<img src={UpArrow} alt='' />
					</Button>
				</div> */}
				<div className='mt-7 lg:mt-0 flex  menu-list'>
					<ul className='w-80'>
						<li>
							<LinkBtn
								text='Sellers'
								theme='light'
								size='small'
								font='body'
								onClick={() => scrollIntoView('sellers')}
							/>
						</li>
						<li>
							<LinkBtn
								text='Channels'
								theme='light'
								size='small'
								font='body'
								onClick={() => scrollIntoView('channels')}
							/>
						</li>
						<li>
							<LinkBtn
								text='About'
								theme='light'
								size='small'
								font='body'
								onClick={() => scrollIntoView('about')}
							/>
						</li>
						<li>
							<LinkBtn
								text='Contact'
								theme='light'
								size='small'
								font='body'
								onClick={() => setShowModal('contact')}
							/>
						</li>
					</ul>
					<Button
						theme='light'
						// className='ml-auto px-2 py-2 md:hidden self-start'
						className="ml-auto mr-0.4 px-1.5 py-2 md:hidden self-start"
						onClick={() => scrollIntoView('pageTop')}>
						<img src={UpArrow} alt='' />
					</Button>
				</div>
				<ul className='mt-7 lg:mt-0 contact-details'>
					<li>
						<a
							href='tel: +418000306740'
							className='text-white text-base'>
							+41 (0)800 030 6740
						</a>
					</li>
					<li>
						<a
							href='mailto:info@tixstock.com'
							className='text-white text-base'>
							info@tixstock.com
						</a>
					</li>
					<li>
						<a
							href='https://goo.gl/maps/dfXj1JY56F9c2bAp8'
							className='text-white text-base'
							target='_blank'
							rel='noreferrer'>
							Rue Arnold Winkelried 6, 1201 Geneva 1, Switzerland
						</a>
					</li>
					<li>
						<div
						
						className="text-white text-base"
						target="_blank"
						rel="noreferrer"
						>
						© {new Date().getFullYear()} Tixstock
						</div>
					</li>
				</ul>

				<Button
					theme='light'
					className='ml-auto px-2 py-2 hidden lg:inline-block absolute right-0'
					onClick={() => scrollIntoView('pageTop')}>
					<img src={UpArrow} alt='' />
				</Button>
			</article>
			{/* <article className='relative flex items-start lg:ml-96 mt-20 lg:mt-56'>
				<div className='lg:ml-12'></div>

				<ul className='w-80'>
					<li className='mb-1 text-white font-heading'>
						© 2022 Tixstock
					</li>
				</ul>
			</article> */}
		</section>
	);
}

export default Footer;
